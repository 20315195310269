.qmu-input {
  background: #CAC7C7;
  outline: none;
  border: none;
  margin: 5px;
  padding: 4px 6px;
  min-width: 200px;
  min-height: 26px;
  border-radius: 4px;
  width: initial;

  &.narrow {
    min-height: initial;
  }

  &.error {
    background: #FFC7C7;
  }

}

textarea.qmu-input {
  min-height: 75px;
}