.NewHome {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  .Card {
    width: 90vw;
    max-width: 400px;
    height: calc(90vw / 16 * 9);
    max-height: calc(400px / 16 * 9);
    border-radius: 6px;
    background-image: url("/QMU_CARD.png");
    background-size: cover;
    box-shadow: 0 0 10px #00000070;
    font-family: Archia;
    font-weight: 600;

    .MemberIdHolder {
      position: relative;
    }

    .MemberId {
      right: 0;
      margin: 10px;
      font-size: 20px;
      line-height: 20px;
      position: absolute;
    }

    .GuidHolder {
      position: relative;
      height: 100%;
    }

    .Guid {
      bottom: 0;
      margin: 8px;
      font-size: 20px;
      line-height: 20px;
      position: absolute;
    }

  }

  .qmu-button,
  .InfoBoxHolder {
    width: 90vw;
    max-width: 400px;
    margin: 25px 0;
  }

  .gPay {
    height: 30px;
    background: url("/logos/save_to_phone.svg");
    background-color: white;
    background-size: 40%;
    background-repeat: no-repeat;
    background-position: center;
    transition: background-color 0.25s;
    &:hover {
      background-color: #ffffffdd;
    }
  }

}