.qmu-button {
  background-color: #00000040;
  border: none;
  outline: none;
  padding: 4px 6px;
  margin: 5px;
  min-width: 212px;
  min-height: 26px;
  border-radius: 4px;
  font-size: 18px;
  color: white;
  cursor: pointer;

  &.primary {
    background-color: #FF0B70;
  }


  &:disabled,
  &[disabled] {
    background-color: #CAC7C7;
  }
}