.Login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;

  span {
    text-decoration: underline;
    cursor: pointer;
  }

  .EmailPassHolder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    text-align: center;
  }


  .GoogleButton {
    height: 30px;
    background: url("/logos/sign_in_google.svg");
    background-color: white;
    background-size: 40%;
    background-repeat: no-repeat;
    background-position: center;
    transition: background-color 0.25s;
    &:hover {
      background-color: #ffffffdd;
    }
  }

  &>div {
    margin: 10px 0;
  }

}