.GraphicsItem {
    background-color: white;
    color: black;
    padding: 20px;
    margin: 20px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    text-align: start;
    max-width: 500px;

    p {
        margin: 5px;
    }

    .title {
        font-size: 20px;
        font-weight: bold;
    }
    .buttons {
        margin: 5px;
    }
}