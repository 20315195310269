.GraphicsDashboard {
    background-color: #242847;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* font-size: calc(10px + 2vmin); */
    color: white;
    
    .content {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
    }

    .notification-info, .sorting {
        margin: 20px 10px 0 10px;
    }
}