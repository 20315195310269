.BoardReport {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
    color: white;

    .DocList {
        display: flex;
        // flex: 1;
        flex-direction: column;
        align-items: stretch;
        justify-content: center;
        flex-wrap: wrap;
        font-size: calc(10px + 2vmin);
        background-color: #20243f;

        .ListItem {
            padding: 10px 25px;
            cursor: pointer;

            &.clicked,
            &:hover {
                background-color: #00000050;
            }
        }
    }

    .DocView {
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        padding: 20px 40px;
    }
}