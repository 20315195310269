.BoardReportEntry {
    padding: 50px 0;
    display: flex;
    flex-direction: column;
    justify-content: stretch;

    h2, p {
        margin: 10px 0;
    }

    .Questions {
        margin: 10px;
        border-left: 2px solid white;

    }

}