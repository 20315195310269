body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #242847;
  color: white;

  // #root {
  //   height: 100vh;

  //   > div:nth-child(2) {
  //     min-height: calc(100vh - 3em);
  //   }
  // }

}

html, body, #root {
  min-height: 100% !important;
  height: 100%;
  max-height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
  font-family: 'Archia';
  font-weight: 600;
  src: local('Archia'), url(./fonts/Archia-SemiBold.otf) format('opentype');
}
