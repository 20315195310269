.InfoBoxHolder {

}

.InfoBox {
  border: none;
  outline: none;
  min-width: 200px;
  min-height: 26px;
  padding: 10px;
  border-radius: 4px;
  font-size: 18px;
  background-color: #00000040;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;

  &.primary {
    background-color: #FF0B70;
  }

  .svg-inline--fa {
    padding: 0 10px 0 0;
  }
}