.ScreenWrapper {
  min-height: 100%;
  height: 100%;
  max-height: 100%;
  width: 100%;

  .ScreenWrapperContent {
    min-height: 100%;
    height: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

}