.App {
  background-color: #242847;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* font-size: calc(10px + 2vmin); */
  color: white;

  .App-form {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    flex: 1;
    max-width: 500px;
    padding: 20px;
  }


  input, textarea, button {
    background: #CAC7C7;
    outline: none;
    /* border: 1px solid white; */
    border: none;
    padding: 4px 6px;
    margin-bottom: 14px;
    min-height: 26px;
    min-width: 200px;
    border-radius: 4px;
    width: initial;
  }

  textarea {
    min-height: 75px;
  }

  button {
    font-weight: bold;
    font-size: 24px;
    margin-right: 0;
  }

  label {
    align-self: start;
    padding: 2px;
    font-size: 16px;
  }
}